<script setup lang="ts">
import axios from "axios";
import {ref, Ref} from "vue";
import {Log} from "../types";
import {useI18n} from "vue-i18n";
const {t} = useI18n();

let data: Ref<{logs: Array<Log>}> = ref({logs: []});

const loadLogs = async () => {
    try {
        const logsResponse = await axios.get('/api/logs');
        data.value = logsResponse.data;

    } catch (error) {

    }
}

loadLogs();

</script>

<template>

    <h2 class="text-4xl mb-4 font-bold dark:text-white">Log</h2>

    <div class="rounded-lg mt-12" v-if="data">

        <div class="relative overflow-hidden bg-white shadow-md dark:bg-gray-800 sm:rounded-lg">
            <div class="overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" class="px-2 py-3">#</th>
                            <th scope="col" class="px-2 py-3">{{ t('log.table.headings.products_number') }}</th>
                            <th scope="col" class="px-2 py-3">{{ t('log.table.headings.updated') }}</th>
                            <th scope="col" class="px-2 py-3">{{ t('log.table.headings.notUpdated') }}</th>
                            <th scope="col" class="px-2 py-3">{{ t('log.table.headings.errors') }}</th>
                            <th scope="col" class="px-2 py-3">{{ t('log.table.headings.start') }}</th>
                            <th scope="col" class="px-2 py-3">{{ t('log.table.headings.end') }}</th>
                            <th scope="col" class="px-2 py-3">{{ t('log.table.headings.details') }}</th>
                        </tr>
                    </thead>

                    <tbody>

                    <tr class="dark:border-gray-600 hover:bg-gray-100 dark:hover:bg-gray-700 border-b" v-for="(item, index) in data.logs">
                        <td class="px-2 py-3 capitalize">
                            <span>{{ item.batch }}</span>
                        </td>

                        <td class="px-2 py-3 capitalize font-medium text-white">
                            <span>{{ item.total }}</span>
                        </td>

                        <td class="px-2 py-3 capitalize font-medium text-white">
                            <span>{{ item.success }}</span>
                        </td>

                        <td class="px-2 py-3 capitalize">
                            <span> {{ item.skipped }}</span>
                        </td>

                        <td class="px-2 py-3 capitalize">
                            <span>{{  item.failures }}</span>
                        </td>

                        <td class="px-2 py-3 text-xs font-medium">
                            <span>{{ item.start }} </span>
                        </td>

                        <td class="px-2 py-3 text-xs font-medium">
                            <span>{{ item.end }}</span>
                        </td>

                        <td class="px-2 py-3">
                            <router-link :to="{ name: 'dashboard.log.detail', params: { id: item.batch }}" class="font-medium text-blue-600 dark:text-blue-500 hover:underline">{{ t('log.table.more') }}</router-link>
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>

</template>
