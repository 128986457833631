<script setup lang="ts">

import {ref} from "vue";
import {BuybackStrategy} from "../types";
import axios from "axios";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const props = defineProps<{
    strategy: BuybackStrategy;
}>();

const emit = defineEmits(['success', 'error', 'submit'])

const strategy = ref(props.strategy);

let absPricePositioning: number = Math.abs(strategy.value.price_positioning);
let greaterOrLesser: string = strategy.value.price_positioning > 0 ? 'greater' : 'lesser';


const onSubmit = async (event: Event) => {

    emit('submit');

    try {
        const response = await axios.put('/api/buyback-strategies/' + strategy.value.category.id, {
            rounding_value: strategy.value.rounding_value,
            rounding_type: strategy.value.rounding_type,
            price_positioning: greaterOrLesser === 'greater' ? absPricePositioning : -absPricePositioning,
        });

        emit('success', response.data as Array<BuybackStrategy>);

    } catch (error: any) {
        emit('error', error.response.data.message);
    }
}

</script>


<template>
    <div class="w-full bg-gray-800 sm:rounded-lg p-4 text-gray-400 mt-8">
        <h4 class="text-lg font-medium text-white mb-2">{{ t('strategies.buyback.card.title', {category: strategy.category.name}) }}</h4>

        <div v-if="strategy.category.slug === 'pc'" class="p-4 mb-4 text-sm rounded-lg bg-gray-700 text-gray-400 border-blue-800" role="alert">
            <div class="flex  text-gray-200">
                <svg class="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
                </svg>
                <span class="font-medium">Additional strategies</span>
            </div>

            <span class="block mt-4 text-gray-200">Macbook (incl. Pro, Air)</span>
            <ul class="block mt-2 list-disc pl-4">
                <li>2014, 2015, 2016, 2017 and screen 13” <strong class="inline-block ml-2 text-gray-200">x 1.45</strong></li>
                <li>2014, 2015, 2016, 2017 and screen 15” <strong class="inline-block ml-2 text-gray-200">x 0.6</strong></li>
                <li>2018, 2019 (all screens) <strong class="inline-block ml-2 text-gray-200">x 1.4</strong></li>
                <li>2020, 2022 (all screens) <strong class="inline-block ml-2 text-gray-200">x 1.2</strong></li>
            </ul>

            <span class="block mt-4 text-gray-200">iMac</span>
            <ul class="block mt-2 list-disc pl-4">
                <li>2014, 2015 and screen 21” <strong class="inline-block ml-2 text-gray-200">x 1.25</strong></li>
                <li>2017 and screen 21” <strong class="inline-block ml-2 text-gray-200">x 0.8</strong></li>
                <li>2014 and screen 27” <strong class="inline-block ml-2 text-gray-200">x 0.7</strong></li>
                <li>2019, 2020 (all screens) <strong class="inline-block ml-2 text-gray-200">x 0.8</strong></li>
            </ul>
        </div>

        <form  @submit.prevent="onSubmit">
            <div class="leading-10">
                {{ t('strategies.buyback.card.firstSentence') }}

                <div class="inline-flex w-16 mx-4 relative">
                    <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">%</span>
                    <input v-model="absPricePositioning" type="text" id="min-price" class="pl-2.5 pr-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
                </div>

                <div class="inline-flex w-32 mx-4 relative">
                    <select v-model="greaterOrLesser" class="py-1.5 px-2.5 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full bg-gray-700 dark:border-gray-600 placeholder-gray-400 text-white dark:focus:ring-blue-500">
                        <option value="greater">{{ t('strategies.buyback.card.greater') }}</option>
                        <option value="lesser">{{ t('strategies.buyback.card.lesser') }}</option>
                    </select>
                </div>


                {{ t('strategies.buyback.card.secondSentence') }}


                <div class="inline-flex w-16 mx-4 relative">
                    <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">€</span>
                    <input v-model="strategy.rounding_value" type="text" id="min-price" class="pl-2.5 pr-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
                </div>

            </div>

            <div class="flex mt-4">
                <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">{{ t('strategies.buyback.card.apply') }}</button>
            </div>
        </form>
    </div>
</template>
