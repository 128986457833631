import {createI18n} from "vue-i18n";
import itIT from "./locales/it-IT.json";
import enGB from "./locales/en-GB.json";

export const i18n = createI18n({
    locale: "en-GB",
    fallbackLocale: "it-IT",
    legacy: false,
    messages: {
        "it-IT" : itIT,
        "en-GB": enGB
    }
});
