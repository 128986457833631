<script setup lang="ts">

import {ref} from "vue";
import {Strategy} from "../types";
import axios from "axios";
import {useI18n} from "vue-i18n";
const {t} = useI18n();

const props = defineProps<{
    strategy: Strategy;
}>();

const emit = defineEmits(['success', 'error', 'submit'])

const strategy = ref(props.strategy);

const onRoundingTypeChange = (event: Event) => {
    console.log(strategy.value.rounding_type)

    switch (strategy.value.rounding_type) {
        case 'point_nine_euro':
            strategy.value.rounding_value = .9;
            break;
        case 'nine_euro':
            strategy.value.rounding_value = 9;
            break;
        default:
            break;
    }
}

const onSubmit = async (event: Event) => {
    emit('submit');

    try {
        const response = await axios.put('/api/strategies/' + strategy.value.category.id, {
            rounding_value: strategy.value.rounding_value,
            rounding_type: strategy.value.rounding_type,
            min_price_percentage: strategy.value.min_price_percentage,
            max_price_percentage: strategy.value.max_price_percentage,
        });

        emit('success', response.data);

    } catch (error: any) {
        emit('error', error.response.data.message);
    }
}

</script>

<template>
    <div class="w-full bg-gray-800 sm:rounded-lg p-4 text-gray-400 mt-8">
        <h4 class="text-lg font-medium text-white mb-2">{{ t('strategies.card.title', {category: strategy.category.name}) }}</h4>

        <form @submit.prevent="onSubmit">
            <div class="leading-10">
                {{ t('strategies.card.minSentence') }}

                <div class="inline-flex w-24 mx-4 relative">
                    <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">%</span>
                    <span class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400">-</span>
                    <input v-model="strategy.min_price_percentage" type="text" id="min-price" class="px-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
                </div>

                {{ t('strategies.card.maxSentence') }}

                <div class="inline-flex w-24 mx-4 relative">
                    <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400">%</span>
                    <span class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400">+</span>
                    <input v-model="strategy.max_price_percentage" type="text" id="min-price" class="px-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
                </div>

                {{ t('strategies.card.roundSentence') }}

                <div class="inline-flex w-32 mx-4 relative">
                    <select @change="onRoundingTypeChange" v-model="strategy.rounding_type" class="py-1.5 px-2.5 border border-gray-300 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full bg-gray-700 dark:border-gray-600 placeholder-gray-400 text-white dark:focus:ring-blue-500">
                        <option value="euro">Euro</option>
                        <option value="nine_euro">9 Euro</option>
                        <option value="point_nine_euro">.9 Euro</option>
                    </select>
                </div>

                <div class="inline-flex" v-show="strategy.rounding_type === 'percentage' || strategy.rounding_type === 'euro'">
                    {{ t('strategies.card.of') }}

                    <div class="inline-flex w-16 mx-4 relative">
                        <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400" v-if="strategy.rounding_type === 'percentage'">%</span>
                        <span class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none text-gray-400" v-if="strategy.rounding_type !== 'percentage'">€</span>
                        <input v-model="strategy.rounding_value" type="text" id="min-price" class="pl-2.5 pr-8 py-1.5 focus:outline-none rounded-lg bg-gray-700 border border-gray-600 text-white focus:border-blue-500 block w-full text-sm">
                    </div>
                </div>

            </div>

            <div class="flex mt-4">
                <button type="submit" class="text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-blue-600 hover:bg-blue-700 focus:ring-blue-800">{{ t('strategies.card.apply') }}</button>
            </div>
        </form>
    </div>
</template>
