import Pusher from "pusher-js";

export default class Broadcaster {

    private static instance: Broadcaster;

    connector: Pusher;

    private constructor() {
        this.connector = new Pusher(import.meta.env.VITE_PUSHER_APP_KEY, {
            channelAuthorization: {
                endpoint: "/broadcasting/auth",
                transport: "ajax",
                params: {},
                headers: {},
            },
            cluster: 'eu'
        });
    }

    public static get(): Broadcaster
    {
        if (!Broadcaster.instance) {
            Broadcaster.instance = new Broadcaster();
        }

        return Broadcaster.instance;
    }
}
