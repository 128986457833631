import {createApp, Plugin} from 'vue'
import App from './App.vue'
import Login from './components/Login.vue';
import Dashboard from './components/Dahsboard.vue';
import Prices from './components/Prices.vue';
import CatalogDetail from './components/CatalogDetail.vue';
import Catalog from './components/Catalog.vue';
import Log from './components/Log.vue';
import Strategies from './components/Strategies.vue';
import ClickOutside from "./directives/ClickOutside";
import {createRouter, createWebHashHistory} from "vue-router";
import {useUserStore} from "./stores/UserStore";
import {createPinia} from "pinia";

import './style.css'
import axios from "axios";
import LogDetail from "./components/LogDetail.vue";
import BuybackStrategies from "./components/BuybackStrategies.vue";
import Competitors from "./components/Competitors.vue";
import CompetitorItem from "./components/CompetitorItem.vue";
import {i18n} from "./i18n";

/**
 * Stores
 */
const pinia = createPinia();

/**
 * Middleware
 */
const guest = () => {
    const user = useUserStore();
    if (user.check) {
        return { name: 'dashboard.catalog'};
    }

    return true;
}

const auth = () => {
    const user = useUserStore();
    if (!user.check) {
        return { name: 'login'};
    }

    return true;
}

const redirectToCategory = () => {
    const user = useUserStore();
    const firstCategory = user.profile?.categories.at(0);

    return {name: 'dashboard.prices.category', params: {id: firstCategory?.id}};
}

const redirectToFirstCompetitor = () => {
    const user = useUserStore();
    const firstCompetitor = user.profile?.vendors.at(0);

    return {name: 'dashboard.competitors.detail', params: {id: firstCompetitor?.id}};
}

const shouldShowCatalog = () => {
    const user = useUserStore();
    if (!user.configuration?.show_catalog) {
        return {name: 'dashboard.prices'}
    }

    return true;
}

const shouldShowCompetitor = () => {
    const user = useUserStore();
    if (user.profile?.vendors.length === 0) {
        return {name: 'dashboard.prices'}
    }

    return true;
}

const isBuybackEnabled = () => {
    const user = useUserStore();
    if (!user.profile?.buyback_enabled) {
        return {name: 'dashboard.prices'}
    }

    return true;
}

const redirectToResellStrategies = () => {
    return {name: 'dashboard.strategies.resell'};
}

/**
 * Routes
 */
const routes = [
    {
        path: '/',
        component: Login,
        name: 'login',
        beforeEnter: [guest],
    },
    {
        path: '/dashboard',
        component: Dashboard,
        name: 'dashboard',
        redirect: {
            name: 'dashboard.prices',
        },
        children: [
            {
                path: 'prices',
                name: 'dashboard.prices',
                component: Prices,
                beforeEnter: [auth, redirectToCategory],
            },
            {
                path: 'prices/:id',
                name: 'dashboard.prices.category',
                component: Prices,
                props: true,
                beforeEnter: [auth],
            },
            {
                path: 'catalog',
                name: 'dashboard.catalog',
                component: Catalog,
                beforeEnter: [auth, shouldShowCatalog],
            },
            {
                path: 'log',
                name: 'dashboard.log',
                component: Log,
                beforeEnter: [auth, shouldShowCatalog],
            },
            {
                path: 'log/:id',
                name: 'dashboard.log.detail',
                component: LogDetail,
                props: true,
                beforeEnter: [auth, shouldShowCatalog],
            },
            {
                path: 'catalog/:id',
                name: 'dashboard.catalog.detail',
                component: CatalogDetail,
                props: true,
                beforeEnter: [auth, shouldShowCatalog]
            },
            {
                path: 'strategies',
                name: 'dashboard.strategies',
                component: Strategies,
                beforeEnter: [auth, redirectToResellStrategies],
            },
            {
                path: 'strategies/resell',
                name: 'dashboard.strategies.resell',
                component: Strategies,
                beforeEnter: [auth, shouldShowCatalog],
            },
            {
                path: 'strategies/buyback',
                name: 'dashboard.strategies.buyback',
                component: BuybackStrategies,
                beforeEnter: [auth, isBuybackEnabled],
            },
            {
                path: 'competitors',
                name: 'dashboard.competitors',
                component: Competitors,
                beforeEnter: [auth, shouldShowCompetitor, redirectToFirstCompetitor],
            },
            {
                path: 'competitors/:id',
                name: 'dashboard.competitors.detail',
                component: Competitors,
                props: true,
                beforeEnter: [auth, shouldShowCompetitor],
            },
            {
                path: 'competitors/:id/items',
                name: 'dashboard.competitor.items',
                component: Competitors,
                props: true,
                beforeEnter: [auth, shouldShowCompetitor],
            },
            {
                path: 'competitors/:id/items/:itemId',
                name: 'dashboard.competitor.items.detail',
                component: CompetitorItem,
                props: true,
                beforeEnter: [auth, shouldShowCompetitor],
            }
        ]
    },
]

const router = createRouter({
    routes: routes,
    history: createWebHashHistory(),
});


/**
 * App
 */
const app = createApp(App);

app.directive('click-outside', ClickOutside);
app.use(router);
app.use(pinia);
app.use(i18n as any);
app.mount('#app');


/**
 * Axios interceptors
 */
axios.interceptors.response.use(response => response, async error => {
    const user = useUserStore();

    if (error.response.status === 401) {
        user.logout();

        await router.push({name: 'login'});
    }

    return Promise.reject(error);
});
